<template>
  <v-dialog
    v-model="connectDialog"
    width="600"
    transition="dialog-bottom-transition"
    persistent
  >
    <template v-if="isConnectingFinished || isConnectingFailed">
      <ConnectSocialMediaPage
        v-if="connectSocialMediaPage"
        :social-media-pages="socialMediaPages"
        :active-tab="activeTab"
        :content="content"
        @back="openConnectSocialMediaDialog"
        @close="closeDialog"
        @savedContent="$emit('savedContent')"
        @reconnectToMeta="reconnectToMeta"
      />
      <ConnectSocialMedia
        v-if="connectSocialMediaDialog"
        :social-media-pages="socialMediaPages"
        :content-kind="content.textKind"
        :has-instagram-connection="hasInstagramConnection"
        :has-linkedin-connection="hasLinkedinConnection"
        @openConnectSocialMediaPage="openConnectSocialMediaPage"
        @close="closeDialog"
        @connectToMeta="generateMetaConnection"
        @connectToLinkedin="loginToLinkedin"
      />
    </template>
    <v-card
      v-if="isConnecting"
      class="mx-auto"
      max-width="600"
    >
      <v-card-title class="pa-0">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <div class="d-flex justify-space-between toolbar">
            <v-toolbar-title class="my-auto">
              {{ $t('social-media.info-dialog') }}
            </v-toolbar-title>
          </div>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0">
        <ProgressCircular />
        <v-card-title class="d-flex justify-center">
          <span class="text-center">{{ $t('alerts.social-media.connecting-to-social-media.info') }}</span>
        </v-card-title>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ConnectSocialMediaPage from '@/modules/contentCreator/creator/SocialMedia/ConnectSocialMediaPage.vue'
import ConnectSocialMedia from '@/modules/contentCreator/creator/SocialMedia/ConnectSocialMedia.vue'
import ProgressCircular from '@/components/ProgressCircular.vue'
import GET_FACEBOOK_PAGES from '@/modules/contentCreator/creator/queries/getFacebookPages.gql'
import GET_LINKEDIN_PAGES from '@/modules/contentCreator/creator/queries/getLinkedinOrganizations.gql'
import KindType, { KindName } from '@/modules/contentCreator/creator/enums/KindType'
import CONNECT_TO_SOCIAL_MEDIA from '@/modules/contentCreator/creator/queries/connectSocialMedia.gql'

import {
  facebookSDKInitialized,
  getAccessToken,
  initFbSDK,
  loginToFacebook,
  loginToInstagram,
  logoutFromFacebook
} from '@/lib/facebook'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import { SocialMedia } from '@/modules/contentCreator/creator/enums/SocialMedia'
import { FilterType } from '@/modules/contentCreator/creator/enums/FilterType'

const FB_APP_ID = process.env.VUE_APP_FB_APP_ID
const LINKEDIN_CLIENT_ID = process.env.VUE_APP_LINKEDIN_APP_CLIENT_ID
export default {
  components: { ProgressCircular, ConnectSocialMediaPage, ConnectSocialMedia },
  props: {
    activeTab: {
      type: String,
      default: FilterType.ALL
    },
    content: {
      type: Object,
      default: () => {}
    },
    socialMediaConnections: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      state: 'connected',
      socialMediaPages: null,
      connectSocialMediaDialog: false,
      connectSocialMediaPage: false,
      connectDialog: true
    }
  },
  computed: {
    isConnecting () {
      return this.state === 'isConnecting'
    },
    isConnectingFinished () {
      return this.state === 'connected'
    },
    isConnectingFailed () {
      return this.state === 'error'
    },
    hasFacebookConnection () {
      return this.socialMediaConnections &&
        this.socialMediaConnections?.facebook
    },
    hasInstagramConnection () {
      return this.socialMediaConnections &&
        this.socialMediaConnections?.instagram
    },

    hasLinkedinConnection () {
      return this.socialMediaConnections &&
        this.socialMediaConnections?.linkedin
    },
    isContentKindFacebookPost () {
      return this.content.textKind === KindType.facebookPost
    },
    isContentKindInstagramPost () {
      return this.content.textKind === KindType.instagramPost
    },
    isContentKindLinkedinPost () {
      return this.content.textKind === KindType.linkedinPost
    }
  },
  async mounted () {
    if (this.hasFacebookConnection) {
      await this.getFacebookPages()
    }

    if (this.hasLinkedinConnection) {
      await this.getLinkedinPages()
    }

    if (!this.hasLinkedinConnection && this.isCallbackAfterLinkedinAuth()) {
      this.$tracking.event('Content Creator', this.$tracking.trackingEvents.CLICKED, 'Connect LinkedIn')
      await this.generateLinkedinConnection()
    }

    const isFacebookPost = this.content.textKind === KindType.facebookPost
    const isInstagramPost = this.content.textKind === KindType.instagramPost
    const isLinkedinPost = this.content.textKind === KindType.linkedinPost
    if ((isFacebookPost && this.hasFacebookConnection) ||
      (isInstagramPost && this.hasInstagramConnection) ||
      (isLinkedinPost && this.hasLinkedinConnection)) {
      this.openConnectSocialMediaPage()
    } else {
      this.openConnectSocialMediaDialog()
    }
  },

  methods: {
    isCallbackAfterLinkedinAuth () {
      return this.$route.query.linkedinAuthCallback
    },
    closeDialog () {
      this.$emit('close')
      this.connectDialog = false
    },
    openConnectSocialMediaDialog () {
      this.connectSocialMediaDialog = true
      this.connectSocialMediaPage = false
    },
    openConnectSocialMediaPage () {
      this.connectSocialMediaDialog = false
      this.connectSocialMediaPage = true
    },
    async reconnectToMeta (kind) {
      if (kind.name === KindName.facebookPost) {
        if (!facebookSDKInitialized()) {
          await initFbSDK(FB_APP_ID)
        }

        if (getAccessToken()) {
          await logoutFromFacebook()
        }

        await this.generateMetaConnection(KindName.facebookPost)
      }
    },
    async generateMetaConnection (kind) {
      if (kind === KindName.facebookPost || (kind === KindName.instagramPost && !this.hasFacebookConnection)) {
        this.$tracking.event('Content Creator', this.$tracking.trackingEvents.CLICKED, 'Connect Facebook')
        await this.generateFacebookConnection()
      }

      if (kind === KindName.instagramPost) {
        this.$tracking.event('Content Creator', this.$tracking.trackingEvents.CLICKED, 'Connect Instagram')
        await this.generateInstagramConnection()
      }

      if (this.isConnectingFailed) {
        this.openConnectSocialMediaDialog()
      } else {
        this.openConnectSocialMediaPage()
      }

      this.$emit('refreshSocialMediaConnections')
      await this.getFacebookPages()
    },
    composeLinkedinAuthRedirectUrl () {
      const currentPath = this.$route.path
      const url = new URL(currentPath, window.location.origin)
      url.searchParams.set('linkedinAuthCallback', '1')
      return url.toString()
    },
    removeUrlSegmentAfterWord (word) {
      const baseUrl = window.location.href
      const index = baseUrl.indexOf(word)
      if (index !== -1) {
        const newUrl = baseUrl.substring(0, index + word.length)
        window.history.pushState({}, '', newUrl)
      }
    },
    async generateLinkedinConnection () {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')

      if (code) {
        try {
          await this.connectToSocialMedia(code, null, SocialMedia.LINKEDIN)
          this.$tracking.event('Content Creator', this.$tracking.trackingEvents.CONNECTED, 'LinkedIn')
          showSnackbarMessage('success', this.$t('alerts.social-media.connect-to-linkedin.success'))
          this.$emit('refreshSocialMediaConnections')
          await this.getLinkedinPages()
          this.$emit('updateContent', JSON.parse(sessionStorage.getItem('linkedinAuthCallbackContent')))
          sessionStorage.removeItem('linkedinAuthCallbackContent')
          this.removeUrlSegmentAfterWord('content-creator')
          this.state = 'connected'
        } catch (err) {
          showSnackbarMessage('error', this.$t('alerts.social-media.connect-to-linkedin.error'))
          this.state = 'error'
        }
      }
    },

    async loginToLinkedin () {
      sessionStorage.setItem('linkedinAuthCallbackContent', JSON.stringify(this.content))
      window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${this.composeLinkedinAuthRedirectUrl()}&scope=rw_organization_admin%20r_organization_social%20w_organization_social`
    },

    async generateFacebookConnection () {
      if (!facebookSDKInitialized()) {
        await initFbSDK(FB_APP_ID)
      }
      try {
        this.state = 'isConnecting'
        const login = await loginToFacebook()
        await this.connectToSocialMedia(login.authResponse.accessToken, login.authResponse.userID, SocialMedia.FACEBOOK)
        this.$tracking.event('Content Creator', this.$tracking.trackingEvents.CONNECTED, 'Facebook')
        showSnackbarMessage('success', this.$t('alerts.social-media.connect-to-facebook.success'))
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.social-media.connect-to-facebook.error'))
        this.state = 'error'
      }
    },

    async generateInstagramConnection () {
      if (!facebookSDKInitialized()) {
        await initFbSDK(FB_APP_ID)
      }

      this.state = 'isConnecting'
      try {
        await loginToInstagram()
        await this.connectToSocialMedia(null, null, SocialMedia.INSTAGRAM)
        this.$tracking.event('Content Creator', this.$tracking.trackingEvents.CONNECTED, 'Instagram')
        this.state = 'connected'
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.social-media.connect-to-instagram.error'))
        this.state = 'error'
      }
    },

    async connectToSocialMedia (accessToken, userId, socialMedia) {
      try {
        await this.$apollo.mutate({
          mutation: CONNECT_TO_SOCIAL_MEDIA,
          variables: {
            input: {
              type: socialMedia,
              accessToken: accessToken,
              userId: userId,
              redirectUri: this.composeLinkedinAuthRedirectUrl()
            }
          }
        })
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.social-media.connecting-to-social-media.error'))
      }
    },

    async getLinkedinPages () {
      this.state = 'isConnecting'
      try {
        const { data } = await this.$apollo.query({
          query: GET_LINKEDIN_PAGES,
          fetchPolicy: 'no-cache'
        })

        if (!data?.linkedinOrganizations || data.linkedinOrganizations.length === 0) {
          this.socialMediaPages.linkedin = null
          this.state = 'connected'
          return
        }

        if (data.linkedinOrganizations) {
          if (this.socialMediaPages) {
            this.socialMediaPages.linkedin = this.formatLinkedinPages(data.linkedinOrganizations)
          } else {
            this.socialMediaPages = {
              linkedin: this.formatLinkedinPages(data.linkedinOrganizations)
            }
          }
        }

        this.state = 'connected'
      } catch (err) {
        this.handleError()
      }
    },

    async getFacebookPages () {
      this.state = 'isConnecting'
      try {
        const { data } = await this.$apollo.query({
          query: GET_FACEBOOK_PAGES,
          fetchPolicy: 'no-cache'
        })

        if (!data?.facebookPages || data.facebookPages.length === 0) {
          this.socialMediaPages = null
          this.state = 'connected'
          return
        }

        if (this.socialMediaPages) {
          this.socialMediaPages.facebook = this.formatFacebookPages(data.facebookPages)
        } else {
          this.socialMediaPages = {
            facebook: this.formatFacebookPages(data.facebookPages)
          }
        }

        if (this.hasInstagramConnection) {
          this.socialMediaPages.instagram = this.formatInstagramPages(data.facebookPages)
        }

        this.state = 'connected'
      } catch (err) {
        this.handleError()
      }
    },
    formatFacebookPages (pages) {
      return pages.map(page => ({
        ...page,
        selected: this.isContentKindFacebookPost ? page.isActive : false,
        isActive: page.isActive,
        type: KindType.facebookPost
      })).sort((a, b) => b.isActive - a.isActive)
    },

    formatInstagramPages (pages) {
      const instagramPages = pages.filter(page => page.instagram_business_account)
      return instagramPages.map(page => {
        const instagramAccount = page.instagram_business_account
        return {
          facebookId: page.id,
          id: instagramAccount.id,
          name: instagramAccount.username,
          selected: this.isContentKindInstagramPost ? page.isActive : false,
          isActive: page.isActive,
          type: KindType.instagramPost
        }
      }).sort((a, b) => b.isActive - a.isActive)
    },

    formatLinkedinPages (pages) {
      const hasAddedPageToUser = pages.some(page => page.isActive)
      return pages.map((page) => ({
        ...page,
        id: Math.random(),
        selected: this.isContentKindLinkedinPost ? page.isActive : false,
        isActive: !hasAddedPageToUser ? true : page.isActive ? page.isActive : false,
        addedPageToUser: hasAddedPageToUser,
        type: KindType.linkedinPost
      })).sort((a, b) => b.isActive - a.isActive)
    },

    handleError () {
      this.state = 'connected'
      this.openConnectSocialMediaPage()
      showSnackbarMessage('error', this.$t('alerts.social-media.something-went-wrong'))
    }

  }

}
</script>
