<template>
  <v-dialog
    v-model="isDialogOpen"
    max-width="700"
    :persistent="!disabled"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title v-text="$t('content-creator.edit-text')" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="pb-0 mt-2">
        <BlurredBackdrop
          :density="1"
          :is-blurred="disabled"
        >
          <template v-slot:info>
            <div>
              {{ $t('alerts.upselling.upgrade.0') }}
              <router-link
                to="/settings/contract"
                class="pointer white--text font-weight-bold"
              >
                {{ $t('alerts.upselling.upgrade.link') }}
              </router-link>
              {{ $t('alerts.upselling.upgrade.1') }}
            </div>
          </template>
          <h5 class="text-h6 mb-2">
            {{ this.$t('content-creator.customize-text') }}
          </h5>

          <v-textarea
            v-model="text"
            outlined
            :disabled="disabled"
            rows="8"
          />
        </BlurredBackdrop>
      </v-card-text>
      <v-divider />
      <v-spacer />
      <v-card-actions class="d-flex justify-end">
        <v-btn
          text
          @click="close"
        >
          {{ $t('buttons.content-creator.close') }}
        </v-btn>
        <v-btn
          text
          :disabled="disabled"
          color="primary"
          @click="update"
        >
          {{ $t('buttons.content-creator.apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BlurredBackdrop from '@/components/BlurredBackdrop.vue'
import feature from '@/mixins/feature'

export default {
  components: {
    BlurredBackdrop
  },
  mixins: [feature],
  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      disabled: false,
      isDialogOpen: true,
      text: this.content.text
    }
  },
  mounted () {
    const feature = this.getFeature(this.featureNames.CONTENT_CREATOR)
    this.disabled = !feature.config.canEditPost
  },

  methods: {
    update () {
      this.$emit('update', this.text)
      this.close()
    },
    close () {
      this.isDialogOpen = false
      this.$emit('close')
    }
  }
}
</script>
