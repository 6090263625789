export const PostType = Object.freeze({
  facebookPost: 'facebookPost',
  instagramPost: 'instagramPost',
  linkedinPost: 'linkedinPost',
  facebookAds: 'facebookAds',
  gmbNewsPost: 'gmbNewsPost',
  gmbProductDescription: 'gmbProductDescription',
  customerMailing: 'customerMailing',
  websiteText: 'websiteText',
  interview: 'interview',
  download: 'download'
})
