import KindType from '../enums/KindType'
export const partMapper = {
  [KindType.facebookPost]: ['text', 'image'],
  [KindType.instagramPost]: ['image', 'text'],
  [KindType.linkedinPost]: ['text', 'image'],
  [KindType.facebookAds]: ['text', 'image'],
  [KindType.gmbNewsPost]: ['text', 'image'],
  [KindType.gmbProductDescription]: ['text', 'image'],
  [KindType.customerMailing]: ['text'],
  [KindType.interview]: ['text'],
  [KindType.websiteText]: ['image', 'text']
}
