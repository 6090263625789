<template>
  <v-tooltip
    top
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <v-btn
          :text="!primary"
          :outlined="!primary"
          :disabled="disabled || isLoading"
          :loading="isLoading"
          :class="'download-btn pointer '+ btnClass"
          :color="primary ? 'primary' : ''"
          elevation="0"
          @click="downloadPost"
        >
          <v-icon
            class="mr-sm-2 pt-1"
            size="14"
          >
            mdi-download
          </v-icon>

          {{ $t('buttons.content-creator.download') }}
        </v-btn>
      </div>
    </template>
    <span v-if="disabled">{{ $t('buttons.content-creator.tooltip.disable-download') }}</span>
    <span v-else>{{ $t('buttons.content-creator.tooltip.download-post') }}</span>
  </v-tooltip>
</template>

<script>
import SAVE_CONTENT from './queries/saveContent.gql'
import bus, { eventNames } from '@/lib/eventBus'
import { AnimationEvents } from '@/lib/animation'

import { createAndDownloadZip } from '@/lib/generateZip'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'
import trackingEvents from '@/lib/trackingEvents'
import { buildUrlWithTracking } from '@/modules/contentCreator/creator/lib/urlWithTracking'
import kebabCase from '@/mixins/kebabCase'

export default {
  mixins: [featureMixin, brandingMixin, kebabCase],
  props: {
    primary: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    savedContentId: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    imageSource: {
      type: String,
      default: ''
    },
    kind: {
      type: String,
      default: ''
    },
    topic: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    topicId: {
      type: String,
      default: ''
    },
    btnClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    async downloadPost () {
      const object = ['#all', '#today', '#week', '#month', '#month'].includes(this.$route.hash) ? 'Content History' : 'Content Creator'
      this.$tracking.event(object, this.$tracking.trackingEvents.CLICKED, 'Download Content')

      this.isLoading = true
      await this.downloadZipFile()
      await this.storeSavedContent(null)
      this.$emit('savedContent')
      this.isLoading = false
    },
    async storeSavedContent (postType) {
      try {
        await this.$apollo.mutate({
          mutation: SAVE_CONTENT,
          variables: {
            input: {
              text: this.text ?? null,
              imagePath: this.image ?? null,
              imageSource: this.imageSource,
              textKind: this.kind,
              topicId: this.topicId,
              landingPageId: this.landingPageId,
              postedOn: postType ? [postType] : [],
              slug: this.topic,
              url: buildUrlWithTracking(this.url, this.kind, this.imageSource),
              downloaded: postType === null
            }
          }
        })
        this.$gtm.trackEvent({
          event: postType ? trackingEvents.CONTENT_CREATOR_SHARED : trackingEvents.CONTENT_CREATOR_DOWNLOADED,
          value: {
            type: this.$t(`content-creator.text-kind-types.${this.transformToKebabCase(this.kind)}`),
            topic: this.topic
          }
        })
        bus.$emit(eventNames.TRIGGER_ANIMATION, AnimationEvents.CONTENT_DOWNLOADED)
        showSnackbarMessage('info',
          this.$t('alerts.content-creator.save-content.success'),
          [{ text: this.$t('alerts.content-creator.save-content.success-link'), link: '/content-creator#all' }]
        )
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.content-creator.save-content.error'))
      }
    },

    async downloadZipFile () {
      if (!this.text && !this.image) {
        showSnackbarMessage('error', this.$t('alerts.content-creator.general.error'))
        return
      }

      const zipFileCreated = await createAndDownloadZip(
        {
          text: this.text ?? null,
          imagePath: this.image ?? null,
          textKind: this.$t(`content-creator.text-kind-types.${this.transformToKebabCase(this.kind)}`),
          topic: this.topic,
          url: buildUrlWithTracking(this.url, this.kind, this.imageSource)
        })

      if (!zipFileCreated) {
        showSnackbarMessage('error', this.$t('alerts.content-creator.create-zip-file.error'))
      }
    }
  }
}
</script>

<style scoped>
  .pointer {
    cursor: pointer;
  }

  .download-btn:after {
    text-decoration: underline;
  }

  ::v-deep fieldset   {
    min-width: 64px;
    padding: 0 16px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.12);
  }

  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
  }

  .download-post-btn-in-history {
    height: 30px !important;
    padding: 0 8px !important;
    font-size: 10px !important;
  }

  @media (max-width: 600px) {
    .download-post-btn-in-history {
      width: 100%;
    }
  }
</style>
