<template>
  <v-card
    class="mx-auto"
    max-width="600"
  >
    <v-card-title class="px-0 py-0">
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <div class="d-flex justify-space-between toolbar">
          <v-toolbar-title class="my-auto">
            {{ $t('social-media.link-accounts') }}
          </v-toolbar-title>
          <v-btn
            icon
            dark
            @click="closeDialog"
          >
            <v-icon large>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-spacer />
      </v-toolbar>
    </v-card-title>
    <v-card-text class="px-0 py-0">
      <p class="font-weight-bold pa-5 mb-3">
        {{ $t('social-media.choose-the-platform-to-connect-and-post', {readablePlatformName}) }}
      </p>
      <v-card-text class="card py-0">
        <v-row dense>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-card
              class="pointer card-item mb-2"
              outlined
              @click="connectToLinkedin()"
            >
              <v-list-item>
                <v-list-item-avatar
                  tile
                  size="30"
                  class="my-2"
                >
                  <v-img :src="KindTypeIcon.linkedinPost" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-overline font-weight-bold">
                    {{ $t('buttons.social-media.connect-linkedin') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between mt-5 pt-0">
      <v-btn
        elevation="0"
        text
        :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 12 + 'px' : ''}"
        @click="closeDialog"
      >
        {{ $t('buttons.social-media.close') }}
      </v-btn>
      <v-btn
        v-if="socialMediaPages"
        text
        elevation="0"
        color="primary"
        :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 12 + 'px' : ''}"
        @click="openConnectSocialMediaPage()"
      >
        {{ $t('buttons.social-media.already-linked-accounts') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { KindName, KindTypeIcon } from '@/modules/contentCreator/creator/enums/KindType'
import brandingMixin from '@/mixins/branding'
export default {
  mixins: [brandingMixin],
  props: {
    socialMediaPages: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    KindTypeIcon () {
      return KindTypeIcon
    },
    KindName () {
      return KindName
    }
  },
  methods: {
    openConnectSocialMediaPage () {
      this.$emit('openConnectSocialMediaPage')
    },
    connectToLinkedin () {
      this.$emit('connectToLinkedin')
    },
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>
<style >
.toolbar {
  width: 100%;
}
</style>
