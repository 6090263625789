<template>
  <div>
    <Filters
      v-if="showDialog"
      :topics="topicItems"
      :kinds="availableKinds"
      :filter="filter"
      @close="showDialog = false"
      @applyFilters="applyFilters"
    />
    <div class="d-md-flex justify-md-space-between">
      <v-btn
        class="my-2 ma-sm-2 w-xs-full"
        color="primary"
        text
        outlined
        :disabled="disabled"
        @click="openFiltersDialog"
      >
        <v-icon
          left
          x-small
        >
          mdi mdi-tune-variant
        </v-icon>

        {{ $t('buttons.content-creator.filters') }}
        <v-badge
          v-if="filter.numberOfAppliedFilters"
          color="red"
          :content="filter.numberOfAppliedFilters"
          offset-y="-2"
          offset-x="-2"
        >
          <v-icon
            icon="$vuetify"
            size="x-large"
          />
        </v-badge>
      </v-btn>
      <div class="justify-space-between d-none d-sm-flex">
        <v-tabs
          v-model="section"
          right
          slider-size="1"
          :disabled="disabled"
          @change="refetchSavedContents"
        >
          <v-tab
            replace
            to="#all"
            :disabled="disabled"
          >
            {{ $t('content-creator.tabs.all') }}
          </v-tab>
          <v-tab
            replace
            to="#today"
            :disabled="disabled"
          >
            {{ $t('content-creator.tabs.today') }}
          </v-tab>
          <v-tab
            replace
            to="#week"
            :disabled="disabled"
          >
            {{ $t('content-creator.tabs.this-week') }}
          </v-tab>
          <v-tab
            replace
            to="#month"
            :disabled="disabled"
          >
            {{ $t('content-creator.tabs.this-month') }}
          </v-tab>
          <v-tab
            replace
            to="#year"
            :disabled="disabled"
          >
            {{ currentYear }}
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <v-divider class=" divider" />
    <v-tabs-items
      v-if="hasAvailableSavedContents"
      v-model="section"
      touchless
    >
      <SavedContentTab
        :disabled="disabled"
        :filter-value="FilterType.ALL"
        :saved-contents="availableSavedContents"
        @savedContent="refetchSavedContents"
      />
      <SavedContentTab
        :disabled="disabled"
        :filter-value="FilterType.TODAY"
        :saved-contents="availableSavedContents"
        @savedContent="refetchSavedContents"
      />
      <SavedContentTab
        :disabled="disabled"
        :filter-value="FilterType.WEEK"
        :saved-contents="availableSavedContents"
        @savedContent="refetchSavedContents"
      />
      <SavedContentTab
        :disabled="disabled"
        :filter-value="FilterType.MONTH"
        :saved-contents="availableSavedContents"
        @savedContent="refetchSavedContents"
      />
      <SavedContentTab
        :disabled="disabled"
        :filter-value="FilterType.YEAR"
        :saved-contents="availableSavedContents"
        @savedContent="refetchSavedContents"
      />
    </v-tabs-items>
    <div
      v-if="hasAvailableSavedContents"
      class="text-center mt-10 mb-5"
    >
      <v-pagination
        v-model="currentPage"
        :disabled="disabled"
        :length="Math.ceil(savedContents.total / 20)"
        :total-visible="7"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="updatePage"
      />
    </div>
    <info-box
      v-if="!hasAvailableSavedContents && !isGenerating"
      :info-message="$t('alerts.content-creator.save-content.info')"
    />
    <ProgressCircular v-if="isGenerating" />
  </div>
</template>

<script>
import GET_TOPICS from './queries/getTopics.gql'
import GET_SAVED_CONTENTS from '@/modules/contentCreator/creator/queries/getSavedContent.gql'
import KindType, { KindTypeIcon } from '@/modules/contentCreator/creator/enums/KindType'
import { NAMED_WEEKDAY_WITH_YEAR_WITH_TIME } from '@/mixins/date'
import { FilterType } from '@/modules/contentCreator/creator/enums/FilterType'
import Filters from '@/modules/contentCreator/creator/Filters.vue'
import { textTruncate } from '@/lib/truncate'
import { PostType } from '@/modules/contentCreator/creator/enums/PostType'
import ProgressCircular from '@/components/ProgressCircular.vue'
import SavedContentTab from '@/modules/contentCreator/creator/SavedContentItem.vue'
import InfoBox from '@/components/InfoBox.vue'
import feature from '@/mixins/feature'
import kebabCase from '@/mixins/kebabCase'

export default {
  components: { SavedContentTab, ProgressCircular, Filters, InfoBox },
  mixins: [feature, kebabCase],
  props: {
    updateSavedContents: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      disabled: false,
      filter: {
        selectedKinds: [],
        selectedTopics: [],
        numberOfAppliedFilters: 0
      },
      savedContents: {
        total: 0,
        savedContents: []
      },
      section: FilterType.ALL,
      showDialog: false,
      landingPageIds: null,
      currentPage: 1
    }
  },
  computed: {
    FilterType () {
      return FilterType
    },
    PostType () {
      return PostType
    },
    availableKinds () {
      return Object.keys(KindType).map(key => {
        return {
          value: key,
          text: this.$t(`content-creator.text-kind-types.${this.transformToKebabCase(key)}`),
          icon: KindTypeIcon[key]
        }
      })
    },
    isGenerating () {
      return this.$apollo.loading
    },
    hasAvailableSavedContents () {
      return this.availableSavedContents.length > 0
    },
    currentYear () {
      return new Date().getFullYear()
    },
    topicItems () {
      return this.topics
        .map((topic) => {
          return {
            value: topic.id,
            text: topic.name,
            type: topic.landingpage ? topic.landingpage.type : 'TOPIC'
          }
        })
        .reduce((acc, { type, ...rest }) => {
          if (type in acc) {
            acc[type].items.push(rest)
          } else {
            acc.TOOL.items.push({ type, ...rest })
          }
          return acc
        }, {
          TOPIC: { items: [], header: 'Topics' },
          TOOL: { items: [], header: 'Tools' },
          GUIDE: { items: [], header: 'Ratgeber' },
          CHECKLIST: { items: [], header: 'Checklisten' }
        })
    },
    availableSavedContents () {
      return this.savedContents.savedContents.map((content) => ({
        id: content.id,
        text: content.text,
        kind: content.textKind,
        textKind: this.$t(`content-creator.text-kind-types.${this.transformToKebabCase(content.textKind)}`),
        image: content.imagePath,
        imageSource: content.imageSource,
        landingPageId: content.landingPageId,
        topicId: content.topicId,
        createdAt: this.formatDate(content.createdAt, NAMED_WEEKDAY_WITH_YEAR_WITH_TIME),
        icon: KindTypeIcon[content.postedOn[0]],
        postedOn: content.postedOn !== PostType.download ? Array.isArray(content.postedOn) ? content.postedOn : [content.postedOn] : null,
        slug: content.slug,
        url: content.url,
        downloaded: content.downloaded,
        socialMediaPosts: content.socialMediaPosts
      }))
    }
  },
  watch: {
    updateSavedContents () {
      this.refetchSavedContents()
    }
  },
  mounted () {
    this.disabled = !this.getFeature(this.featureNames.CONTENT_CREATOR)?.config?.isHistoryEnabled
  },
  methods: {
    refetchSavedContents () {
      this.resetCurrentPage()
      this.$apollo.queries.savedContents.refetch()
    },
    resetCurrentPage () {
      this.currentPage = 1
    },
    updatePage (currentPage) {
      this.currentPage = currentPage
    },
    textTruncate,
    openFiltersDialog () {
      this.showDialog = true
    },
    applyFilters (filter) {
      this.filter = filter
    }
  },
  apollo: {
    topics: {
      query: GET_TOPICS
    },
    savedContents: {
      query: GET_SAVED_CONTENTS,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          input: {
            timeRange: this.section,
            textKinds: this.filter.selectedKinds,
            topics: this.filter.selectedTopics,
            page: this.disabled ? 1 : this.currentPage,
            ...(this.disabled && { perPage: 3 })
          }
        }
      }
    }
  }
}
</script>

<style scoped>
  .divider {
    border-color: #F1F1F4;
  }
</style>
