<template>
  <div>
    <component
      :is="getComponent(contentKind)"
      :social-media-pages="socialMediaPages"
      :has-instagram-connection="hasInstagramConnection"
      :has-likedin-connection="hasLinkedinConnection"
      @openConnectSocialMediaPage="openConnectSocialMediaPage"
      @connectToFacebook="connectToMeta(KindName.facebookPost)"
      @connectToInstagram="connectToMeta(KindName.instagramPost)"
      @connectToLinkedin="connectToLinkedin(KindName.linkedinPost)"
      @close="closeDialog"
    />
  </div>
</template>
<script>
import KindType, { KindName, KindTypeIcon } from '@/modules/contentCreator/creator/enums/KindType'
import brandingMixin from '@/mixins/branding'
import ConnectInstagram from '@/modules/contentCreator/creator/SocialMedia/ConnectInstagram.vue'
import ConnectFacebook from '@/modules/contentCreator/creator/SocialMedia/ConnectFacebook.vue'
import ConnectLinkedin from '@/modules/contentCreator/creator/SocialMedia/ConnectLinkedin.vue'

export default {
  components: { ConnectLinkedin, ConnectFacebook, ConnectInstagram },
  mixins: [brandingMixin],
  props: {
    socialMediaPages: {
      type: Object,
      default: () => {}
    },
    contentKind: {
      type: String,
      default: () => ''
    },
    hasInstagramConnection: {
      type: Boolean,
      default: () => false
    },
    hasLinkedinConnection: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    KindName () {
      return KindName
    },
    KindType () {
      return KindType
    },
    KindTypeIcon () {
      return KindTypeIcon
    }
  },
  methods: {
    getComponent (contentKind) {
      return {
        [KindType.facebookPost]: 'ConnectFacebook',
        [KindType.instagramPost]: 'ConnectInstagram',
        [KindType.linkedinPost]: 'ConnectLinkedin'
      }[contentKind]
    },
    openConnectSocialMediaPage () {
      this.$emit('openConnectSocialMediaPage')
    },
    connectToMeta (kind) {
      this.$emit('connectToMeta', kind)
    },
    connectToLinkedin (kind) {
      this.$emit('connectToLinkedin', kind)
    },
    closeDialog () {
      this.$emit('close')
    }
  }
}

</script>
